
tr.dx-row.dx-data-row.dx-row-lines.dx-column-lines > td {
    padding: 4px !important;
}


#tabellaCss .dx-datagrid-text-content{
    font-size: 14px;
    white-space: pre-line;
}
#tabellaCss .dx-data-row{
    font-size: 14px;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td{
    background-color: #007bff3b;
  }
