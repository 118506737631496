@import url('https://fonts.googleapis.com/css?family=Inter:regular,bold,semibold');

html, #root {
    height: 100%;
    background: #2e4150;
}

body {
    margin: 0;
    font-family: "Inter" !important;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    background-color: #F3F3F4 !important;
}

.btn-success {
    background-color: #1ab394 !important;
    border-color: #1ab394 !important;
    color: #fff !important;
}

    .btn-success:hover, .btn-success:active, .btn-success:focus {
        background-color: #18a689 !important;
        border-color: #18a689 !important;
        color: #fff !important;
        background-image: none !important;
        outline: 0 !important;
        text-decoration: none !important;
        box-shadow: none !important;
    }

.btn-info {
    background-color: #23c6c8 !important;
    border-color: #23c6c8 !important;
    color: #fff !important;
}

    .btn-info:hover, .btn-info:active, .btn-info:focus {
        background-color: #21b9bb !important;
        border-color: #21b9bb !important;
        color: #fff !important;
        background-image: none !important;
        outline: 0 !important;
        text-decoration: none !important;
        box-shadow: none !important;
    }

.btn-danger {
    background-color: #ed5565 !important;
    border-color: #ed5565 !important;
    color: #fff !important;
}

    .btn-danger:hover, .btn-danger:active, .btn-danger:focus {
        background-color: #ec4758 !important;
        border-color: #ec4758 !important;
        color: #fff !important;
        background-image: none !important;
        outline: 0 !important;
        text-decoration: none !important;
        box-shadow: none !important;
    }

.btn-warning {
    background-color: #f8ac59 !important;
    border-color: #f8ac59 !important;
    color: #fff !important;
}

    .btn-warning:hover, .btn-warning:active, .btn-warning:focus {
        background-color: #f7a54a !important;
        border-color: #f7a54a !important;
        color: #fff !important;
        background-image: none !important;
        outline: 0 !important;
        text-decoration: none !important;
        box-shadow: none !important;
    }

.btn-default {
    background-color: #e7eaec !important;
    border-color: #e7eaec !important;
    color: #fff !important;
}

    .btn-default:hover, .btn-default:active, .btn-default:focus {
        background-color: #d2d2d2 !important;
        border-color: #d2d2d2 !important;
        color: #fff !important;
        background-image: none !important;
        outline: 0 !important;
        text-decoration: none !important;
        box-shadow: none !important;
    }

.btn-primary {
    background-color: #337ab7 !important;
    border-color: #337ab7 !important;
    color: #fff !important;
}

    .btn-primary:hover, .btn-primary:active, .btn-primary:focus {
        background-color: #296293 !important;
        border-color: #296293 !important;
        color: #fff !important;
        background-image: none !important;
        outline: 0 !important;
        text-decoration: none !important;
        box-shadow: none !important;
    }

button.close:focus {
    outline: none;
}

#root {
    box-sizing: border-box;
    color: black;
    /* color: #676a6c; */
    overflow-x: hidden;
    line-height: 17.1429px;
    text-size-adjust: 100%;
}

.navbar-default, .sidebar-collapse {
    background-color: transparent;
    border-color: #2f4050;
    box-sizing: border-box;
    padding: 0 !important;
    display: block !important;
    background-color: #2f4050;
}

.nav {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

    .nav > li {
        position: relative;
        display: block;
    }

ul {
    margin-top: 0;
}

.nav-header {
    padding: 33px 25px;
    background-color: #2f4050;
    background-image: url("./immagini/header-profile.png");
    width: 180px;
}

.nav-header-closed {
    background-image: url("./immagini/header-profile.png");
    width: 70px;
    height: 61px;
}

.siglaUtente {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: white;
    padding: 25px 5px;
}

.profile-element {
    position: relative;
}

.menu-utente {
    color: rgb(103, 106, 108);
    border: medium none;
    float: left;
    left: 0;
    position: absolute;
    text-shadow: none;
    list-style: none outside none;
    top: 100%;
    z-index: 1000;
    margin-top: 5px;
    padding: 0;
    border-radius: 0;
    width: 120px;
    display: block;
    background-color: white;
    animation-name: myMenu;
    animation-timing-function: ease-in-out;
    animation-duration: 0.4s;
    animation-direction: normal;
}

@keyframes myMenu {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.menu-utente.chiuso {
    display: none;
}

.menu-utente > li {
    padding: 5px 0;
}

    .menu-utente > li > a, .menu-utente > li > div {
        margin: 0;
        padding: 5px 10px;
        line-height: 15px;
        border-radius: 3px;
        color: inherit;
        text-align: left;
        font-weight: normal;
        text-decoration: none;
    }

    .menu-utente > li:hover {
        color: #262626;
        text-decoration: none;
        background-color: #f5f5f5;
    }

.list-closed {
    width: 70px;
    height: 50px;
}

    .list-closed > div {
        padding: 18px 20px 14px 25px;
        color: #a7b1c2 !important;
        display: block;
    }

        .list-closed > div:hover {
            background-color: #293846;
            color: white !important;
        }

.iconaMenu-closed {
    font-size: 16px;
}

.divider {
    margin: 0;
    padding: 0 !important;
    height: 2px;
    overflow: hidden;
    background-color: #e5e5e5;
}

.nav-header div {
    display: block;
    text-decoration: none;
    cursor: pointer;
    color: #8095a8;
}

    .nav-header div:hover, .nav-header div:focus {
        text-decoration: none;
        outline: 0;
    }

.clear {
    display: block;
}

.user-logged {
    display: block;
    margin-top: 5px;
    color: White;
    font-size: 12px;
}

.group-logged {
    display: block;
    color: #8095a8;
    font-size: 12px;
}

.freccia-giu {
    margin-left: 5px;
}

.elencoMenu {
    color: rgb(13, 31, 43);
    font-size: 12px;
    background-color: #2f4050;
    width: 180px;
    cursor: pointer;
}

    .elencoMenu.active {
        border-left: 4px solid #19aa8d;
        background-color: #293846;
        height: auto;
        display: block;
        cursor: pointer;
    }

        .elencoMenu.active > .voceMenu {
            color: #fff !important;
        }

.list-closed:hover {
    background-color: #293846;
    color: white !important;
}

.list-closed-active {
    border-left: 4px solid #19aa8d;
    background-color: #293846;
    width: 70px;
    height: 50px;
}

    .list-closed-active:hover > ul {
        display: block;
    }

    .list-closed-active > div {
        padding: 18px 20px 14px 25px;
        color: white !important;
        display: block;
    }

.mini-secondo-liv {
    min-width: 140px;
    border-radius: 0 2px 2px 0;
    height: auto !important;
    opacity: 1;
    left: 65px;
    position: absolute;
    margin-top: -51px;
    background-color: #2f4050;
    padding: 10px 10px 10px 10px;
    font-size: 12px;
    color: #a7b1c2 !important;
    margin-bottom: 0;
    list-style: none;
    text-decoration: none;
    background-attachment: scroll;
    background-clip: border-box;
    background-origin: padding-box;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: initial;
    background-repeat: repeat;
    transition-timing-function: ease;
    -webkit-transition-timing-function: ease;
    transition-duration: 0.35s;
    -webkit-transition-duration: 0.35s;
    transition-property: height, visibility;
    -webkit-transition-property: height, visibility;
    overflow: hidden;
    padding-inline-start: 0;
}

    .mini-secondo-liv > li {
        padding-left: 10px;
    }

.singolaVoce:hover {
    background-color: #293846;
}

.mini-secondo-liv {
    display: none;
}

    .mini-secondo-liv > li > a, .mini-secondo-liv > li > div {
        padding: 10px 10px 10px 15px;
        cursor: pointer;
        display: block;
        font-weight: 600;
        color: #a7b1c2 !important;
    }

        .mini-secondo-liv > li > a.singolaVoce-active, .mini-secondo-liv > li > div.singolaVoce-active {
            color: #fff !important;
            background-color: #293846;
        }

    .mini-secondo-liv li:last-child {
        margin-bottom: 10px;
    }

    .mini-secondo-liv > li > a:hover, .mini-secondo-liv > li > div:hover {
        color: #fff !important;
        text-decoration: none !important;
    }

.voceMenu {
    color: #a7b1c2 !important;
    font-weight: 600;
    padding: 14px 20px 14px 25px;
    position: relative;
    display: block;
    text-size-adjust: 100%;
}

    .voceMenu:hover {
        background-color: #293846;
        color: white !important;
        text-decoration: none;
        outline: 0;
    }

.elencoMenu.active > a.voceMenu {
    color: #fff !important;
}

.iconaMenu {
    margin-right: 10px;
}

.arrow {
    float: right;
    margin-top: 3px;
}

.second-level {
    height: auto;
    display: block;
    background-color: #293846;
    color: #a7b1c2 !important;
    margin-bottom: 0;
    list-style: none;
    text-decoration: none;
    background-attachment: scroll;
    background-clip: border-box;
    background-origin: padding-box;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: initial;
    background-repeat: repeat;
    /* -webkit-transition: min-height .35s ease-in-out;
  -moz-transition: min-height .35s ease-in-out;
  -ms-transition: min-height .35s ease-in-out;
  -o-transition: min-height .35s ease-in-out;
  transition: min-height .35s ease-in-out; */
    /* transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition-duration: 0.35s;
  -webkit-transition-duration: 0.35s;
  transition-property: height;
  -webkit-transition-property: height; */
    overflow: hidden;
    padding-inline-start: 0;
    /* animation-name: apriLivello;
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  animation-direction: normal; */
}

    /* @keyframes myMenu {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
} */

    .second-level.chiuso {
        /* display: none; */
        height: 0px;
    }

    .second-level > li > a, .second-level > li > div {
        padding: 7px 10px 7px 10px;
        padding-left: 52px;
        cursor: pointer;
        display: block;
        font-weight: 600;
        color: #a7b1c2;
    }

    .second-level li:last-child {
        margin-bottom: 10px;
    }

    .second-level > li > a:hover, .second-level > li > div:hover {
        color: #fff !important;
        text-decoration: none !important;
    }

/*CSS COMPONENTE CORPO*/
#page-wrapper {
    width: auto;
    height: auto;
    cursor: inherit;
    position: relative !important;
    background-color: #f3f3f4 !important;
    box-sizing: border-box;
    display: block;
}

.border-bottom {
    border-bottom: 1px solid #e7eaec !important;
}

.navbar-static-top {
    margin-bottom: 0;
    background: #f8f8f9;
    border: 0;
    height: 50px;
    position: relative;
    width: 100vw;
    display: inline-flex;
}



.nav-top-row {
    margin-left: -10px !important;
}

.nav-top-col {
    padding-left: 0 !important;
    height: 50px;
}

.btn-menu {
    background-color: #1ab394 !important;
    border-color: #1ab394 !important;
    color: #fff !important;
    border-radius: 3px !important;
    padding: 4px 12px !important;
    margin: 14px 5px 5px 20px !important;
    font-size: 14px !important;
    float: left;
}

.navbar-header {
    display: block;
    height: 100%;
}

.ricerca {
    height: 50px;
    width: 200px;
    float: left;
    margin-top: 5px;
}

    .ricerca:focus {
        border-color: #f8f8f9;
        outline-color: #f8f8f9;
        outline: -webkit-focus-ring-color auto 0px;
    }

.input-cerca {
    border-width: 0;
    background: inherit;
    font-size: 14px;
    padding: 6px 12px;
    height: inherit;
    color: rgb(103, 106, 108);
}

    .input-cerca:focus {
        border-color: #f8f8f9;
        outline-color: #f8f8f9;
        outline: -webkit-focus-ring-color auto 0px;
    }

.div-ricerca {
    border-width: 0;
    background: inherit;
    font-size: 14px;
    height: inherit;
}

.navbar-top-links {
    float: right;
    margin-right: 200px;
    margin-top: 20px;
    padding: 0;
    list-style: none;
    margin-bottom: 0;
    margin-left: auto;
}

    .navbar-top-links.closed {
        margin-right: 90px;
    }

    .navbar-top-links li {
        display: inline-block;
        position: relative;
    }

    .navbar-top-links > li > div, .navbar-top-links > li > a {
        display: inline;
        font-weight: normal;
        color: #999c9e;
        font-size: 14px;
        padding: 20px 10px;
        position: relative;
    }

        .navbar-top-links > li > div:hover, .navbar-top-links > li > a:hover {
            text-decoration: none;
            color: #999c9e;
        }

.icona-link {
    margin-left: 5px;
    margin-right: 5px;
}

.link {
    cursor: pointer;
}

.paginaHeaderTitolo {
    margin-top: 8px;
    margin-bottom: 4px;
    margin-left: 8px;
    font-size: 18px;
    font-weight: 100;
    line-height: 19.8px;
}

.paginaHeaderDescrizione {
    font-size: 14px;
    font-weight: 100;
    margin-left: 8px;
}

.rowHeader {
    margin-left: -10px !important;
}

.contenutoPagina {
    padding-top: 8px;
    margin-right: 0 !important;
}

/* MEDIA */
@media (min-width: 953px) {
    .navbar-static-side {
        z-index: 800;
        position: absolute !important;
        width: 180px;
    }

        .navbar-static-side.closed, .sidebar-collapse.closed {
            width: 70px;
        }

    #page-wrapper {
        margin: 0 0 0 180px;
    }

    #page-wrapper-closed {
        margin: 0 0 0 70px;
        width: auto;
        cursor: inherit;
        position: relative !important;
        background-color: #f3f3f4 !important;
        box-sizing: border-box;
        display: block;
    }

    .singolaVoce-active {
        color: #fff !important;
    }
}

@media (max-width: 952px) {
    .navbar-static-side {
        display: none !important;
        z-index: 800;
        position: absolute !important;
        width: 70px;
    }

        .navbar-static-side.closed, .sidebar-collapse.closed {
            display: block !important;
        }

    #page-wrapper {
        position: inherit;
        margin: 0 0 0 0;
        width: auto;
    }

    #page-wrapper-closed {
        margin-left: 70px;
        width: auto;
        background-color: #f3f3f4 !important;
    }

    .list-closed:hover {
        background-color: #293846;
        color: white !important;
    }

    .list-closed-active {
        border-left: 4px solid #19aa8d;
        background-color: #293846;
    }

        .list-closed-active > div {
            padding: 18px 20px 14px 25px;
            color: white !important;
            display: block;
        }

    .second-level {
        min-width: 140px;
        border-radius: 0 2px 2px 0;
        height: auto !important;
        opacity: 1;
        left: 65px;
        position: absolute;
        top: 0;
        display: block !important;
        background-color: #2f4050;
        padding: 10px 10px 10px 10px;
        font-size: 12px;
        color: #a7b1c2 !important;
        margin-bottom: 0;
        list-style: none;
        text-decoration: none;
        background-attachment: scroll;
        background-clip: border-box;
        background-origin: padding-box;
        background-position-x: 0%;
        background-position-y: 0%;
        background-size: initial;
        background-repeat: repeat;
        transition-timing-function: ease;
        -webkit-transition-timing-function: ease;
        transition-duration: 0.35s;
        -webkit-transition-duration: 0.35s;
        transition-property: height, visibility;
        -webkit-transition-property: height, visibility;
        overflow: hidden;
        padding-inline-start: 0;
    }

        .second-level.chiuso {
            display: none !important;
            height: 0px;
        }

        .second-level > li {
            padding-left: 10px;
        }

            .second-level > li > a {
                padding: 10px 10px 10px 15px;
                cursor: pointer;
                display: block;
                font-weight: 600;
            }

        .second-level li:last-child {
            margin-bottom: 10px;
        }

        .second-level > li > a:hover, .singolaVoce-active {
            color: #fff !important;
        }

    .ricerca {
        display: none;
    }

    .navbar-top-links {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.dx-datebox-wrapper-calendar .dx-popup-content {
    padding: 0;
    margin: 0 15px;
    background-color: white;
}

.fixButton {
    position: fixed;
    bottom: 21px;
    right: 19px;
    z-index: 3;
    background: white;
    width: 104px;
}


.circle-red {
    background-color: #ef0707;
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.circle-green {
    background-color: #02fc38;
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.clrr {
    background-color: #fc0202;
    border-radius: 50%;
    width: 18px;
    height: 18px;
}

.clrg {
    background-color: #02fc49;
    border-radius: 50%;
    width: 18px;
    height: 18px;
}

.clry {
    background-color: #f4fc02;
    border-radius: 50%;
    width: 18px;
    height: 18px;
}

.clrd {
    background-color: #cfcfcf;
    border-radius: 50%;
    width: 18px;
    height: 18px;
}




.tabellaObj table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.tabellaObj td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 3px;
}

.tabellaObj tr:nth-child(even) {
    background-color: #f3f3f3;
}

.dx-field {
    margin: 1px 0px 3px !important;
    min-height: 25px !important;
}

/*-------------------------------------------------------------------------------------------------------------------*/
/*SCROLLBAR IN GRID*/

/* .dx-scrollbar-vertical, .dx-scrollbar-hoverable {  
  width: 25px;  
  height: 8px;
}  
.dx-scrollbar-vertical, .dx-scrollable-scroll {  
  width: 25px;
  height: 8px;  
}  */

.dx-scrollbar-horizontal .dx-scrollable-scroll {
    height: 14px !important;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable {
    height: 14px !important;
}

.dx-scrollbar-vertical .dx-scrollable-scroll {
    width: 14px !important;
}

.dx-scrollbar-vertical .dx-scrollable-scroll {
    width: 14px !important;
}

.modalDown {
    z-index: 2 !important;
}

.dxc-tooltip {
    z-index: 803 !important; /* Imposta il valore desiderato */
}



/*--------------------------------------------------------------------------------*/
/*CGM*/

.flexbox-container {
    display: flex;
    flex-direction: row;
}

.nav-top-col-cgm {
    padding-left: 0 !important;
    height: 48px;
}

.navbar-top-links-cgm {
    float: right;
    margin-right: 200px;
    margin-top: 0px;
    padding: 0;
    list-style: none;
    margin-bottom: 0;
    margin-left: auto;
}

.title-cgm {
    /*padding-top: 10px;*/
    color: white;
    margin-left: 5px;
    font-size: 16px;
    /*font-family: Inter;*/
    /*font-weight: 600;*/
    line-height: 24px;
    vertical-align: central;
}

#logo-qr-cgm {
    /*margin-top:6px;
    margin-left:10px;
    height:24px;*/
}

.user-logged-cgm {
    font-size: 14px;
    /*font-family: Inter;*/
    line-height: 24px;
    /*font-weight: 400;*/
    vertical-align: central;
    /*margin-top: 24px;*/
    color: white;
    margin-left: 8px;
}

.navbar-static-top-cgm {
    margin-bottom: 0;
    background: #083C72;
    border: 0;
    height: 60px;
    position: relative;
    width: 100vw;
    display: inline-flex;
}

.navbar-static-top-cgm-down {
    margin-bottom: 0;
    background: #083C72;
    border: 0;
    height: 39px;
    position: relative;
    width: 100vw;
    display: inline-flex;
}

.bodyCgm {
    width: 100%;
    height: 100vh;
    background: linear-gradient( to top, #e5e9ef 0%, #e5e9ef 80%, #00234f 50%, #00234f 100% );
    font-family: 'Inter';
    letter-spacing: 0px;
}


.nav-top-col-cgm {
    padding-left: 0 !important;
    height: 60px;
    background: #083C72;
}

.nav-top-col-cgm-2 {
    padding-left: 0 !important;
    height: 40px;
    background: #083C72;
}

.btnCgm {
    border-radius: 4px 4px 0px 0px;
    background-color: #083C72 !important;
}

.btnCgmSelected {
    border-radius: 4px 4px 0px 0px;
    background-color: #456595 !important;
}

.custom-icon {
    max-height: 100%;
    max-width: 100%;
    font-size: 28px;
    display: inline-block;
    vertical-align: middle;
}


.dx-list-item.dx-list-item-selected {
    background-color: #1ab293 !important;
    color: white !important
}

dx-item dx-list-item dx-list-item-selected
{

}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

/*list item cgm*/

.tipoMovimentoItem {
    font-size: 16px;
    padding: 16px !important;
}


.tipoMovimento .dx-list-item-content {
    font-size: 16px;
    padding: 16px !important;
}

.dx-list-item-selected {
    background-color: #E7F3FF !important;
    color: black !important;
}

.dx-list-item.dx-list-item-selected {
    background-color: #E7F3FF !important;
    color: black !important;
}

.dx-item.dx-list-item.dx-state-active {
    background-color: #E7F3FF !important;
}

.dx-item.dx-list-item.dx-state-hover {
    background-color: #E7F3FF !important;
}

.dropdown-item:hover {
    background-color: #E7F3FF !important
}


.dx-widget{
    font-family:"Inter"
}