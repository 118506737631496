
:root {
    --blue-50: #EDF6FF;
    --blue-100: #E7F3FF;
    --blue-200: #E1F0FF;
    --blue-300: #C5E1FE;
    --blue-400: #96C7FD;
    --blue-500: #5A9BFB;
    --blue-600: #206CF7;
    --blue-700: #0052F0;
    --blue-800: #004CE3;
    --blue-900: #0044CC;
    --dark-blue-50: #E5E9EF;
    --dark-blue-100: #BCC7D9;
    --dark-blue-200: #91A3BF;
    --dark-blue-300: #6780A6;
    --dark-blue-400: #456595;
    --dark-blue-500: #1D4D86;
    --dark-blue-600: #15457D;
    --dark-blue-700: #083C72;
    --dark-blue-800: #003366;
    --dark-blue-900: #00234F;
    --grey-50: #FFFFFF;
    --grey-100: #F2F4F7;
    --grey-200: #EAECEF;
    --grey-300: #DBDDE0;
    --grey-400: #B7B9BD;
    --grey-500: #989A9D;
    --grey-600: #6F7174;
    --grey-700: #5C5E60;
    --grey-800: #3D3F41;
    --grey-900: #1D1E20;
    --green-700: #008A33;
    --red-700: #DC2042;
    --yellow-700: #E48B0E;
}


#tabellaCssCgm .dx-datagrid-headers {
    background-color: #f2f4f7 !important;
    height: 48px;
    font-size: 14px;
    font-weight: 400;
    /*height: 48px !important;*/
    
}
    
#tabellaCssCgm .dx-datagrid-text-content {
    color: #6F7174;
}

#tabellaCssCgm .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border-bottom: 0px solid #ddd;
    border-left: 0px;
    border-right: 0px;
    height: 44px !important;
}


#tabellaCssCgm .dx-datagrid-content .dx-datagrid-table .dx-row > td {
    font-size: 16px;
    font-weight: 400;
    color: #1D1E20;
    vertical-align: middle;
    height: 72px;
}


#tabellaCssCgm .dx-widget .dx-datagrid .dx-datagrid-headers .dx-datagrid-content .dx-datagrid-table .dx-row > td {
    text-align: left !important;
    vertical-align: middle
}

#tabellaCssCgm .dx-datagrid-content .dx-datagrid-table .dx-row > td {
    border-left: 0px;
    border-right: 0px;
    vertical-align: middle;
}

#tabellaCssCgm .dx-datagrid-text-content {
    font-size: 14px;
    white-space: pre-line;
}

#tabellaCssCgm .dx-data-row {
    font-size: 14px;
}

#tabellaCssCgm .dx-datagrid-borders > .dx-datagrid-headers {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: none;
}

#tabellaCssCgm .dx-datagrid-borders > .dx-datagrid-rowsview {
    border-left: none;
    border-right: none;
}

#tabellaCssCgm .dx-datagrid-filter-panel {
    pointer-events: none;
    opacity: 0.6;
}


#tabellaCssCgm .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit-with-icons .dx-link {
    color: #0052F0
}

#tabellaCssCgm .dx-pager .dx-page-sizes {
    font-size: 12px;
    font-weight: 400;
}

#tabellaCssCgm .dx-pager .dx-pages .dx-info {
    font-size: 12px;
    font-weight: 400;
}

#tabellaCssCgm .dx-toolbar .dx-toolbar-items-container {
    height: 60px
}

#tabellaCssCgm .dx-texteditor-input {
    height: 44px;
    border-radius: 4px;
    /*border: 1px solid #B7B9BD;*/
    font-size: 16px;
    font-weight: 400;
}

#tabellaCssCgm .dx-toolbar-label .dx-toolbar-item-content > div {
    font-size: 16px;
    font-weight: 400;
    margin-left: 8px;
}

#tabellaCssCgm .dx-datebox-calendar .dx-dropdowneditor-icon {
    color: #6F7174;
}

#tabellaCssCgm .dx-datagrid-header-panel {
    padding-left: 24px;
}

#tabellaCssCgm .dx-toolbar dx-widget dx-visibility-change-handler dx-collection {
    padding-left: 24px;
}

#tabellaCssCgm .dx-widget .dx-datagrid {
    border-radius: 10px;
}

#tabellaCssCgm .dx-datagrid-content {
    padding-left: 24px;
}

#tabellaCssCgm .dx-datagrid.dx-datagrid-borders > .dx-datagrid-pager {
    border-top: none;
}

#tabellaCssCgm .dx-datagrid-header-panel {
    padding-top: 10px;
}