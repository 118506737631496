/* XADM_MONGODB */

label {
    font-size: 14px;
    font-weight: 600;
}

.tabella {
    background-color: #fff;
    margin-left: 0;
}

.titolo {
    font-weight: 700;
}

.listaRisultati {
    padding: 0;
    margin-top: 5px;
}

    .listaRisultati > li {
        list-style: none;
    }

.riga {
    margin-bottom: 10px;
    border-bottom: 1px solid lightgrey;
}

.risultati {
    margin-left: 0;
    margin-top: 15px;
}

/* ------------------------------------------------------------------------------------------------------------------------------ */

/* BANCADATI - PRODOTTI */

#btnCerca {
    width: 120px;
    margin-top: 12px;
    height: 39px;
    border-radius: 5px;
    font-weight: 600;
    width: 90px;
}

#btnCercaConfigurazioneTrasferimenti {
    width: 120px;
    margin-top: 25px;
    height: 39px;
    border-radius: 5px;
    font-weight: 600;
    width: 90px;
}

label {
    font-size: 14px;
    font-weight: 600;
}

.risultatiRicerca {
    margin-top: 15px;
    width: 100%;
}

.risultatiColDx {
    margin-right: 0;
}

.elencoDett {
    padding: 10px 20px 5px 20px;
    border-bottom: 2px solid lightgray;
    font-size: 12px;
    cursor: default;
}

.descrProd {
    font-size: 18px;
    font-weight: 700;
    color: blue !important;
    text-align: center;
    margin-bottom: 5px;
}

.dettagli {
    padding: 0 10px 0 10px;
    font-size: 12px;
    margin-right: 0;
    min-height: 550px;
    margin-left: -10px;
}

    .dettagli > .dx-tabpanel {
        width: 100%;
    }

    .dettagli > div {
        padding-bottom: 5px;
        color: black;
        background-color: white;
    }

        .dettagli > div:last-child {
            padding-bottom: 0;
        }

.titoloDett {
    font-weight: 700;
}

.immagineProdotto {
    width: inherit;
}

.valoreDett {
    text-transform: capitalize;
}

.atcHierarchy {
    margin-top: 10px;
    padding: 10px;
}

.titoloGerarchia {
    color: red !important;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
}

.atcHierarchy > div > ul {
    padding: 0;
    margin: 0;
}

    .atcHierarchy > div > ul > li {
        list-style: none;
        padding-bottom: 5px;
        margin: 5px;
    }

.lista > div, .dettPerFarmacie > div {
    padding-bottom: 5px;
    border-bottom: 0.2px solid lightblue;
    padding-top: 5px;
}

.lista > .noRiga, .dettPerFarmacie > .noRiga {
    border-bottom: none;
}

.dettPerFarmacie {
    padding: 10px 20px 10px 20px;
    font-size: 12px;
    cursor: default;
}

.tabUltimoCosto {
    padding: 10px 20px 10px 20px;
    margin-top: 10px;
}

.tabMovimentoVendite, .tabOfferte, .tabPrezzoStorico {
    padding: 10px 20px 10px 20px;
    margin-top: 15px;
}

.tabMovimentoCarichi {
    padding: 10px 20px 10px 20px;
    margin-top: 20px;
}

.tabMagazzinoGiacDett {
    margin-top: 10px;
}

.bottoniFiltro {
    text-align: center;
}

    .bottoniFiltro > button {
        font-size: 14px;
        width: 150px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 10px;
    }

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* MODAL VERSIONE */
.modal-header {
    padding: 6px 6px;
}

.icon {
    margin-right: 3px;
}

.dialogVersione > .modal-content {
    width: 600px;
    height: 712px;
}

.titoloDialogVersion {
    font-weight: 600;
    margin: 0;
    line-height: 1.42857143;
    color: rgb(103, 106, 108);
    font-size: 16px;
}

.titoloContenutoVersion {
    color: #999c9e;
    font-size: 16px;
    text-align: center;
}

.paragrafiVersion {
    color: #5e9ca0;
    font-size: 18px;
    font-weight: 100;
}

.listeVersion {
    padding-left: 20px;
}

    .listeVersion > li {
        color: rgb(103, 106, 108);
        font-size: 12px;
    }

.btnChiudiVersion {
    background-color: rgb(239, 239, 239);
    color: rgb(103, 106, 108);
    width: 120px;
    font-size: 14px;
}

/* ------------------------------------------------------------------------------------------------------------------------------- */
/* MODAL CAMBIO PASSWORD */

.iconaPwd {
    font-size: 84px;
    color: #e2e3e3;
}

.titoloPassword {
    width: 100%;
    text-align: center;
}

.titoloDialogPassword {
    font-size: 26px;
    line-height: 1.42857143;
    margin-bottom: 0;
    color: rgb(103, 106, 108);
    font-weight: 600;
}

.bodyPassword {
    background: #f8fafb;
}

.btnPassword, .btnChiudiPassword {
    width: 120px;
    font-size: 14px;
    font-weight: 700;
}

.bodyPassword > form > .form-group > input.form-control {
    display: inline-block !important;
    width: 95% !important;
}

.pswVisible {
    position: absolute;
    left: auto;
    right: 10px;
    text-indent: 32px;
    top: 8px;
}

.inputField {
    position: relative;
    height: 26.4px;
    border-radius: 4px;
    margin-bottom: 8px;
}

    .inputField > input {
        width: -webkit-fill-available;
        width: -moz-available;
        width: 100%;
        border-radius: 4px;
        font-size: 12px;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        padding: 6px 12px;
    }

        .inputField > input:focus {
            border-color: #1ab394;
            outline: none;
        }

/* --------------------------------------------------------------------------------------------------------------------------------- */

/* MODAL CONFERMA OPERAZIONI*/
#dialogConfermaBody {
    font-size: 16px;
}

#modalScadenzario{
    width:160px
}

#modalConfermaAnnulla, #modalConfermaOk, #modalIgnora, #modalConfermaProcedi {
    width: 120px;
}

/* --------------------------------------------------------------------------------------------------------------------------------- */

/* DRAWER FILTRI */
.filtriInDrawer {
    margin-left: 0;
    height: 80px;
}

.filtriInDrawerPrezzoPromozione {
    margin-left: 0;
    height: 160px;
}

.dx-toolbar {
    background-color: inherit;
}

/* ----------------------------------------------------------------------------------------------------------------------------------- */

/* ANAGRAFICHE - FORNITORI LOCALI */
.checkFornTrattati {
    padding-top: 33px;
}

#btnAnnulla {
    margin-left: 10px;
    width: 120px;
    margin-top: 22px;
}

.tagFiltri > .cerca {
    background-color: #D3D3D3;
    margin-left: 5px;
}

.tabellaFornitori {
    margin-top: 15px;
}

/* ------------------------------------------------------------------------------------------------------------------------------- */

/* MYCSF - ANAGRAFICA FARMACIA*/
.anagraficaFarmacia {
    background-color: #fff;
    padding: 5px 10px;
    margin: 25px 0 0 0;
}

.titoloAnagrafica {
    font-weight: 700;
    color: #676a6c;
    font-size: 14px;
    margin-top: 10px;
}

.valoreAnagrafica {
    font-size: 14px;
    margin-top: 10px;
}

    .titoloAnagrafica:last-of-type, .valoreAnagrafica:last-of-type {
        margin-bottom: 10px;
    }

/*--------------------------------------------------------------------------------------------------------------------------------*/

/* MYCSF - CONTRATTI E SERVIZI */

.contratti, .servizi {
    margin-top: 25px;
}

/*--------------------------------------------------------------------------------------------------------------------------------*/

/* REPORT - ELENCO REPORT */

.titoloParamsReport, .titoloDialogReport {
    font-size: 20px;
    width: 100%;
}

    .titoloParamsReport > h3, .titoloDialogReport > h3 {
        width: 100%;
        text-align: center;
    }

    .titoloParamsReport > h6, .titoloDialogReport > h6 {
        text-align: center;
    }

.dialogParamsReport {
    width: 600px;
}

#btnIndietroVersion, #btnAvantiVersion, #btnChiudiReport {
    min-width: 120px;
}

/*--------------------------------------------------------------------------------------------------------------------------------*/

/* TORINO RSA - ELENCO RSA */

.nuovaRsa, .tabellaClientiRsa {
    margin-top: 10px;
}

#btnNuovaRsa {
    width: 120px;
    float: right;
}

.titoloModificaRsa {
    font-size: 20px;
    width: 100%;
}

    .titoloModificaRsa > h5 {
        margin-left: 45%;
        margin-top: 5px;
    }

.labelRsa, .rsaCheck {
    margin-top: 15px;
}

.rsaCodice, .rsaDescr {
    margin-top: 5px;
}

#btnOkModifica {
    width: 120px;
}

#btnAnnullaModifica {
    width: 120px;
    margin-right: 5px;
}

.titoloCodiciFiscali {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 700;
}

.aggiungiNuovoCF, .labelInsertCF {
    margin-top: 10px;
}

#RSA_codici_fiscali {
    margin-top: 20px;
}

#btnAggiungiCF {
    margin-top: -2px;
}

/*--------------------------------------------------------------------------------------------------------------------------------*/

/* ORDINI RDA */

#paginaOrdiniRda > .dx-drawer-wrapper > .dx-drawer-panel-content {
    height: 80px !important;
}

#testata {
    margin: 0;
    padding: 10px 10px 20px 10px;
    background-color: white;
}

    #testata > div, #rigaRicerca > .ordineRda > span {
        font-size: 16px;
    }

#rigaRicerca {
    border-top: 2px solid lightblue;
    padding-top: 10px;
}

#rigaTabDettOrdLib, #rigaInviaRDAFarmacia > .aggiungiRiga {
    padding-bottom: 10px;
    border-bottom: 2px solid lightblue;
}

#ultimaRigaTestataRda {
    margin-top: 10px;
}

.valoreTestata, #descrProdSelez {
    font-weight: 700;
}

.ordineRda {
    padding-right: 3px;
}

.ordineRdaGiac {
    padding-left: 3px;
}

#btnChiudiSchedaOrdine, #btnAggiungiProdOrdRda, #btnChiudiDialogProdOrdRda,
#btnChiudiDialogDiffRda, #btnChiudiDialogTrasmetti, #inviaRDAInFarmacia, #inviaRDATrasfInFarmacia,
#btnOkCambiaPagamentoRda, #btnChiudiDialogPagamentoRda, #btnOkCambiaFornitoreRda,
#btnChiudiDialogFornitoreRda {
    width: 120px;
}

#inviaEmailRDA {
    width: 120px;
    margin-top: 15px;
}

#tabellaLogEmail {
    margin-top: 15px;
}

#rigaInviaRDAFarmacia, #rigaInviaEmailRDA {
    margin: 0;
}

#btnTrasmettiOrdine {
    margin-left: 15px;
    width: 140px;
}

#btnDiffRda {
    min-width: 120px;
    margin-right: 15px;
}

#btnAggiungiRda {
    min-width: 120px;
    margin-right: 15px;
}

#btnApplicaSconto {
    min-width: 120px;
    margin-right: 15px;
}

#btnAllineaCosto {
    margin-left: 15px;
}

#btnCercaProdottiDitta {
    margin-top: 30px;
    margin-left:-80px;
    height: 39px;
    border-radius: 5px;
    font-weight: 600;
    width: 90px;
}

.spazioProdSelez {
    text-align: center;
    margin-bottom: 3px;
    padding-top: 10px;
    font-size: smaller;
}

.titoloCercaProdOrdineRda, .titoloDifferenzeRda, .titoloTrasmettiOrdineRda, .titoloDialogPagamentoRda,
.titoloDialogFornitoreRda {
    width: 100%;
}

    .titoloCercaProdOrdineRda > h3, .titoloDifferenzeRda > h3, .titoloTrasmettiOrdineRda > h3, .titoloDialogPagamentoRda > h3,
    .titoloDialogFornitoreRda > h3 {
        font-size: 20px;
        text-align: center;
        width: 100%;
    }

#rigaRicerca, #rigaRicercaOrdLib {
    padding-bottom: 10px;
    margin-right: 0;
    margin-left: 0;
    background: white;
}

    #rigaRicerca > .ordineRda, #rigaRicercaOrdLib > .ordineLibero {
        padding-top: 10px;
    }

    #rigaRicerca > .boxCerca, .colBtnStampa {
        border-right: 2px solid lightblue;
    }


#salvaAuto {
    font-size: 14px !important;
    padding-top: 5px;
    text-align: center;
}

#rigaBtn, .colBtnStampa {
    text-align: right;
}

.pdfFileIcon {
    margin-right: 10px;
}

#rigaInviaEmailRDA {
    margin-top: 25px;
}

    #rigaInviaRDAFarmacia, #rigaInviaEmailRDA > #scrittaInviaEmailRDA {
        font-size: 18px;
    }

        #rigaInviaRDAFarmacia > #scrittaInviaRDAFarmacia {
            padding-top: 5px;
        }

#vendutoFarmRda {
    background-color: paleturquoise;
    text-align: center;
    margin-top: 15px;
}

.rotated {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
}

#vendutoFarmRdaAltra {
    background-color: #51a7fc;
    text-align: center;
    margin-top: 15px;
}

#acquistatoFarmRda {
    background-color: paleturquoise;
    text-align: center;
    margin-top: 15px;
}

#acquistatoFarmRdaAltra {
    background-color: #51a7fc;
    text-align: center;
    margin-top: 15px;
}

#colVendutoRda {
    padding-right: 0;
    padding-left:30px;
}

#colAcquistatoRda {
    padding-right: 0;
    padding-left: 30px;
}

#rigaGridDettagliRDA > .ordineRda > .dx-widget > .dx-datagrid tr.dx-row.dx-data-row.dx-row-lines.dx-column-lines > td {
    padding: 3px !important;
    margin: 0;
    vertical-align: middle;
}

#rigaGridDettagliRDA > .ordineRdaGiac > .dx-widget > .dx-datagrid tr.dx-row.dx-data-row.dx-row-lines.dx-column-lines > td {
    padding: 3px !important;
    margin: 0;
    vertical-align: middle;
}

#spanPagamentoRda, #spanCambiaFornitore {
    margin-left: 10px;
}

.dialogPagamentoRda, .dialogFornitoreRda {
    margin-top: -15px;
}

#colVendutoAltraFarmRda {
    padding-left: 3px;
}

#colAcquistatoAltraFarmRda {
    padding-left: 3px;
}

#tornaValutazioneRda {
    margin-top: 10px;
    text-align: center;
}

#colVendutoAltraFarmRda .dx-widget .dx-datagrid, #colVendutoRda .dx-widget .dx-datagrid {
    font-size: 11px !important;
}

#colAcquistatoAltraFarmRda .dx-widget .dx-datagrid, #colAcquistatoRda .dx-widget .dx-datagrid {
    font-size: 11px !important;
}

#btnInviaMail {
    margin-top: 22px;
    
}

#btnInviaOrdine {
    margin-top: 22px;
    margin-right: 10px;
    
}

#btnCreaOrdine {
    margin-top: 22px;
    margin-right: 10px;
    
}

.lblCostoMinore {
    background-color: #D1FACA;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

.lblCostoMaggiore {
    background-color: #EFB2A5;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

.lblQuantitaInOrdine {
    background-color: #f7a539;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

.lblAvvisoRDA {
    font-size: small;
    /*background-color: #A7E3F1;*/
}

.dx-toast-message {
    white-space: pre-line;
}
/*----------------------------------------------------------------------------------------------------------------------------------*/
/* ORDINI LIBERI */
#ordiniLiberi * {
    font-weight: 700;
}

#ordiniLiberi .dx-toolbar-after {
    top: -7px;
}

#ordiniLiberi .dx-toolbar-items-container {
    height: 30px;
}

#btnNuovoOrdLib {
    float: right;
}

#btImpostazioniOrdLib {
    float: right;
    margin-right: 10px;
}


.dialogNewOrdineLibero {
    margin-top: -30px;
}

.titoloNewOrdineLibero, .titoloPdfOrdineLibero, .titoloTrasmettiOrdLib, .titoloImpostazioniOrdineLibero {
    width: 100%;
}

    .titoloNewOrdineLibero > h3, .titoloPdfOrdineLibero > h3, .titoloTrasmettiOrdLib > h3, titoloImpostazioniOrdineLibero > h3 {
        font-size: 20px;
        text-align: center;
        width: 100%;
    }

#btnAggiungiNewOrdLibero, #btnChiudiDialogNewOrdLibero, #btnStampaPdfOrdLibero, #btnChiudiDialogPdfOrdLibero, #btnSalvaImpostazioniOrdLibero, #btnChiudiDialogImpostazioniOrdLibero, #btnIncludiProdotti {
    width: 120px;
}

#btnIncludiProdotti {
    margin-top: -5px;
}

#btnChiudiOrdineLibero, #btnTrasmettiOrdineLibero {
    width: 120px;
    margin-right: 15px;
}

#btnStampaFarmacia, #btnStampaTotale {
    width: 190px;
}

#primaRigaBtn {
    margin-bottom: 10px;
    text-align: center;
    margin-top: 5px;
}

#secondaRigaBtn {
    text-align: center;
}

#rigaRicercaOrdLib {
    padding-top: 3px;
    padding-bottom: 3px;
}

    #rigaRicercaOrdLib #ricercaProdottiBD {
        font-size: 12px;
    }

    #rigaRicercaOrdLib > .ordineLibero > span {
        font-size: 12px;
    }

    #rigaRicercaOrdLib * {
        height: 35px;
    }

.infoCosto {
    font-size: 12px;
    color: gray;
    padding: 5px;
}

.infoIcon {
    padding-right: 10px;
}

#icoOrdineRicevuto {
    padding-left: 3px;
}

.avvisoTestata {
    padding-top: 50px;
}

.colonnaTabVenduto > .dx-widget > .dx-datagrid {
    font-size: 9px !important;
}

.colonnaTabVenduto .dx-datagrid .dx-row > td {
    padding: 0px 0px !important;
    text-align: center !important;
}

.colonnaTabVenduto {
    padding: 0;
}

    .colonnaTabVenduto tr.dx-row.dx-data-row.dx-row-lines.dx-column-lines > td {
        padding: 1px !important;
    }

.descrProdSelezionato, .colonnaTabVenduto {
    margin-top: 3px;
}

#rigaTabGiacenze {
    margin-left: 0;
    width: 100%;
    margin-right: 15px;
    position: relative;
    bottom: 0;
    padding-bottom: 10px;
}

#rigaTabAltreInfo {
    margin-left: 0;
    width: 100%;
    margin-right: 15px;
    position: relative;
    bottom: 0;
    padding-bottom: 10px;
}

.colonnaTabVenduto {
    text-align: center;
}

#rigaTabDettOrdLib {
    padding-bottom: 3px;
}

    #rigaTabDettOrdLib * {
        font-size: 10px;
    }

    #rigaTabDettOrdLib > .ordineLibero:first-child {
        height: 100%;
    }

    #rigaTabDettOrdLib > .ordineLibero > .dx-widget > .dx-datagrid tr.dx-row.dx-data-row.dx-row-lines.dx-column-lines > td {
        padding: 3px !important;
        margin: 0;
        vertical-align: middle;
    }

#rigaBtnOrdLib {
    text-align: right;
}

#totaleProdotti {
    margin-right: 15px;
}

    #totaleProdotti > .spanProdotti {
        background-color: white;
        color: #18a689;
        border-radius: 100%;
        padding: 2px;
        margin-left: 5px;
    }

#informazioni {
    margin-right: 15px;
}


.rigaCreaNuovoOrdLib {
    margin-bottom: 15px;
}

.labelCreaNuovoOrdLib {
    font-weight: 700;
    font-size: 16px;
    padding-top: 8px;
}

    .labelCreaNuovoOrdLib:last-of-type {
        margin-right: -30px;
    }

#spanScontoNuovoOrdLib {
    display: inline-flex;
}

    #spanScontoNuovoOrdLib > #sconto {
        width: 90px;
        margin-left: 5px;
        margin-top: -7px;
    }

.labelCheckTrasmettiOrdLib {
    font-weight: 700;
    font-size: 16px;
}


.prioritaPrezzoBancaDati {
    padding-bottom: 20px;
}

.leftcontent {
    text-align: left;
}

.lblStatoCreato {
    background-color: #95FFF8;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

.lblStatoTrasmesso {
    background-color: #AAFF9B;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

.lblStatoTrasmessoParzialmente {
    background-color: #FCFF92;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

.lblStatoRicevuto {
    background-color: #6BC5FC;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

#avvisoFiltri {
    font-style: italic;
}

#listItemTrasferitoVenduto .dx-list-item {
    border-top: none;
}

#listItemMagazzino .dx-list-item {
    border-top: none;
}  


.dx-list-item-custom {
    position: static;
    cursor: pointer;
    display: table;
    width: 100%;
    table-layout: fixed;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dx-item-custom {
    outline: 0;
    border-top: none;
}


#btnSalvaImpostazioniOrdineLibero{
    float:right;
    width:120px;
}

#btnAnnullaImpostazioniOrdineLibero {
    float: right;
    width: 120px;
}


.rigaImpostazioneSubOrdLib {
    margin-bottom: 15px;
    margin-left: 60px;
}

.rigaImpostazioneSubSubOrdLib {
    margin-bottom: 15px;
    margin-left: 120px;
}

#alertFatture{
    width:160px;
    height:20px;
    float:right;
}
/*----------------------------------------------------------------------------------------------------------------------------------*/
/* TRASFERIMENTI CONFIGURAZIONE */
#checkBoxTrasferimenti {
    border: 1px solid red;
    content: "";
    -webkit-border-radius: 11px;
    -moz-border-radius: 11px;
    -ms-border-radius: 11px;
    -o-border-radius: 11px;
    border-radius: 11px;
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
}

.row-top-buffer {
    margin-top: 5px;
}

.labelTrasf, .checkTrasf {
    margin-top: 15px;
}

.editTrasf {
    margin-top: 5px;
}

#btnSalva {
    margin-left: 610px;
    width: 120px;
}

#btnSalvaConfigurazioneTrasferimenti {
    margin-top:25px;
    width: 120px;
    float: right;
}

#tabPanelConfigurazioneParametri{
    width:100%;
    height:100%
}

#btnCercaTrasf {
    width: 120px;
    margin-top: 22px;
}

#btnNuovoTrasf {
    width: 120px;
    margin-top: 22px;
    float: right;
}

.trasfInfo {
    font-style: italic;
}

.trasfInfoSub {
    font-style: italic;
    margin-left: 75px;
}

.trasfInfoSubSub {
    font-style: italic;
    margin-left: 150px;
}

.dialogFarmaciaTrasferimento {
    margin-top: -30px;
}

.titoloFarmaciaTrasferimento {
    width: 100%;
}

    .titoloFarmaciaTrasferimento > h3 {
        font-size: 20px;
        text-align: center;
        width: 100%;
    }

.labelFarmaciaTrasferimento {
    font-weight: 700;
    font-size: 16px;
    padding-top: 8px;
}

    .labelFarmaciaTrasferimento:last-of-type {
        margin-right: -30px;
    }




/*-------------------------------------------------------------------------------------------------------------------*/
/* SCREENING */
.centered {
    height: 100vh; /* Magic here */
    display: flex;
    justify-content: center;
    align-items: center;
}

#wrapper {
    text-align: center;
}

#centerHorizontally {
    display: inline-block;
}


#option_button {
    white-space: normal;
    margin-right: 20px;
    margin-bottom: 20px;
    height: 160px;
    width: 400px;
}

    #option_button small {
        display: block
    }

.dialogConsegnaProvetta {
    margin-top: -30px;
}

.dialogFaq > .modal-content {
    height: 600px;
}

.titoloConsegnaProvetta, .titoloPdfConsegnaProvetta, .titoloTrasmettiConsegnaProvetta, .titoloFaq {
    width: 100%;
}

    .titoloConsegnaProvetta > h3, .titoloPdfConsegnaProvetta > h3, .titoloTrasmettiConsegnaProvetta > h3, .titoloFaq > h3 {
        font-size: 20px;
        text-align: center;
        width: 100%;
    }



.rigaCreaConsegnaProvetta {
    margin-bottom: 15px;
}

.labelCreaConsegnaProvetta {
    font-weight: 700;
    font-size: 16px;
    padding-top: 8px;
}

.labelDomandaFaq {
    font-weight: 700;
    font-size: 16px;
    padding-top: 8px;
}

.labelRispostaFaq {
    /*font-weight: 700;*/
    font-size: 16px;
    padding-top: 8px;
}

.labelDomandaFaq:last-of-type {
    margin-right: -30px;
}

.labelRispostaFaq:last-of-type {
    margin-right: -30px;
}

.labelCreaConsegnaProvetta:last-of-type {
    margin-right: -30px;
}

#btnChiudiDialogConsegnaProvetta, #btnStampaPdfConsegnaProvetta, #btnChiudiDialogPdfConsegnaProvetta {
    width: 120px;
}

#btnAggiungiConsegnaProvetta {
    width: 180px;
}

.Screening_codici_kit {
    padding: 0px;
}

.titoloScreening {
    color: black;
}

.testo {
    font-size: 14px;
}

.disabledRow {
    color: silver;
}

    .disabledRow.dx-state-hover td {
        color: silver !important;
    }


/*-------------------------------------------------------------------------------------------------------------------*/
/* PRODOTTO ABBINAMENTO */

#rigaRicercaProdottoAbbinamento {
    padding-top: 3px;
    padding-bottom: 3px;
    height: 70px;
}

#rigaRicercaProdottoAbbinamento {
    font-size: 12px;
}

    #rigaRicercaProdottoAbbinamento * {
        height: 35px;
    }

#rigaRicercaProdottoAbbinamento {
    padding-bottom: 10px;
    margin-right: 0;
    margin-left: 0;
    background: white;
}

    #rigaRicercaProdottoAbbinamento > .prodottoAbbinamento > span {
        font-size: 12px;
        font-weight: bold
    }

    #rigaRicercaProdottoAbbinamento > .prodottoAbbinamento {
        padding-top: 10px;
    }


#cercaProdotti {
    margin-right: 15px;
    width: 100px;
}

    #cercaProdotti > .spanProdotti {
        background-color: white;
        color: #18a689;
        border-radius: 100%;
        padding: 2px;
        margin-left: 5px;
    }



#cercaProdottiAbbinati {
    width: 200px;
    float: right;
}

#aggiungiAbbinamento {
    width: 200px;
    float: right;
    margin-right: 15px;
}

.titoloProdottoAbbinamento {
    color: black;
    font-weight: bold;
    font-size: large;
}

.titoloElencoFattura {
    width: 100%;
}

    .titoloElencoFattura > h3 {
        font-size: 20px;
        text-align: center;
        width: 100%;
    }

.infoProdottoAbbinamento > div[class^="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.btnAbbinaCerca {
    margin-left: 10px
}

#accordion h1 {
    font-size: 18px;
    font-style: oblique;
}

#accordion h1,
#accordion p {
    margin: 0;
}

.dx-theme-material #accordion .dx-accordion-item-opened .dx-accordion-item-title {
    display: flex;
}

.dx-theme-material #accordion .dx-accordion-item-opened h1 {
    align-self: center;
}

.options,
.selected-data {
    padding: 20px;
    background-color: rgba(191, 191, 191, 0.15);
    margin-top: 20px;
}

.selected-data {
    position: relative;
    height: 36px;
}

    .selected-data > .caption {
        position: relative;
        top: 5px;
        margin-right: 10px;
        font-weight: bold;
        font-size: 115%;
    }

    .selected-data > .dx-widget {
        position: absolute;
        left: 140px;
        right: 20px;
        top: 20px;
    }

.options > .caption {
    color: black;
    font-weight: bold;
    font-size: large;
}

.option {
    margin-top: 10px;
}

/*-------------------------------------------------------------------------------------------------------------------*/
/* MONITORAGGIO */
.rightcontent {
    text-align: right;
}

#lblAvvisoInvii {
    background-color: #C08CEF;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

#lblAvvisoEsiti {
    background-color: #F49141;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

#lblAvvisoInviiEsiti {
    background-color: #EEFF39;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

#btnCercaMonitoraggio {
    width: 120px;
    margin-top: 25px;
    height: 39px;
    border-radius: 5px;
    font-weight: 600;
    width: 90px;
}

#btnCercaFornitori {
    width: 120px;
    margin-top: 23px;
    height: 39px;
    border-radius: 5px;
    font-weight: 600;
    width: 90px;
}

#btnAssegnaMonitoraggio {
    width: 200px;
    margin-top: 25px;
    height: 39px;
    border-radius: 5px;
    font-weight: 600;
}

.dx-tile {
    height: 100px;
    width: 250px;
    font-size: large;
    /*background-position: center;
    background-size: cover;
    display: block;*/
}

.lblMonitoraggioClienti {
    font-size: large;
    /*    background-color: #95FFF8;*/
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

.lblMonitoraggioFarmacie {
    font-size: large;
    /*    background-color: #38E74B;*/
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

.lblMonitoraggioUltimaRelease {
    font-size: large;
    /*    background-color: #38E74B;*/
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

.lblMonitoraggioDispensari {
    font-size: large;
    /* background-color: #F6FF95;*/
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

.lblMonitoraggioParafarmacie {
    font-size: large;
    /*   background-color: #F18552;*/
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

.release {
    margin-top: 50px;
}

/*-------------------------------------------------------------------------------------------------------------------*/
/* NOTE RILASCIO */
.titoloNota {
    font-weight: 700;
    font-size: 16px;
    padding-top: 8px;
    font-style: italic
}

.dettaglioNota {
    font-size: 16px;
    padding-top: 8px;
}

/*-------------------------------------------------------------------------------------------------------------------*/
/* NEWSLETTER */
#btnInviaNewsletter {
    width: 180px;
    padding-right: 10px;
}

.labelCreaNuovaNewsletter {
    font-weight: 700;
    font-size: 16px;
    padding-top: 8px;
}

#lblAvvisoSegnalazioni {
    background-color: #EEFF39;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

#btnStoricoNewsLetter {
    margin-right: 10px;
}

#btnImmaginiNewsLetter {
    margin-right: 10px;
}

.noteFileUploadNewsletter {
    font-size: 10pt;
    color: #484848;
    margin-left: 9px;
}

    .noteFileUploadNewsletter > span {
        font-weight: 700;
    }



/*-------------------------------------------------------------------------------------------------------------------*/
/* CONTENITORI */


.dx-datagrid-search-panel {
    margin: 0 0 0 0;
}

.dx-item, .dx-toolbar-item, .dx-toolbar-label {
    max-width: unset !important;
}

.dx-state-disabled.dx-widget {
    pointer-events: none;
    opacity: 0.9;
}

.titoloNewContenitore, .titoloPdfOrdineLibero, .titoloTrasmettiOrdLib, .titoloImpostazioniOrdineLibero {
    width: 100%;
}

    .titoloNewContenitore > h3, .titoloPdfOrdineLibero > h3, .titoloTrasmettiOrdLib > h3, titoloImpostazioniOrdineLibero > h3 {
        font-size: 20px;
        text-align: center;
        width: 100%;
    }


.labelCreaNuovoContenitore {
    font-weight: 700;
    font-size: 16px;
    padding-top: 8px;
}

    .labelCreaNuovoContenitore:last-of-type {
        margin-right: -30px;
    }

#btnInviaContenitore {
    float: right;
    margin-right: 10px;
    width: 180px;
}

#btnImportaContenitore {
    float: right;
    margin-right: 10px;
    width: 280px;
}

#btnImportaContenitoreFile {
    float: right;
    margin-right: 10px;
    width: 280px;
}

#btnAggiungiNewContenitore, #btnChiudiDialogNewContenitore {
    width: 120px;
}

.rigaCreaNuovoContenitore {
    margin-bottom: 15px;
}

#btnNuovoContenitore {
    float: right;
    margin-right: 10px;
    width: 180px;
}


/*-------------------------------------------------------------------------------------------------------------------*/
/* PREZZO PROMOZIONE */
.rigaRicercaProdottoPrezzoPromozione {
    padding-top: 50px;
}

#btnSalvaPrezzoPromozione, #btnSalvaInviaPrezzoPromozione {
    width: 120px;
    margin-right: 10px;
}

.lblFarmaciaRiferimento {
    background-color: #FCFF92;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

.checkPrezzoInfo {
    font-style: italic;
    font-size: smaller;
}


#rigaRicercaProdottoPrezzoPromozione {
    padding-top: 30px;
    padding-bottom: 3px;
    height: 70px;
}

#rigaRicercaProdottoPrezzoPromozione {
    font-size: 12px;
}

    #rigaRicercaProdottoPrezzoPromozione * {
        height: 35px;
    }

#rigaRicercaProdottoPrezzoPromozione {
    padding-bottom: 10px;
    margin-right: 0;
    margin-left: 0;
}

    #rigaRicercaProdottoPrezzoPromozione > .prodottoPrezzoPromozione > span {
        font-size: 12px;
        font-weight: bold;
    }

    #rigaRicercaProdottoPrezzoPromozione > .prodottoPrezzoPromozione {
        padding-top: 10px;
    }

#rigaBottoniProdottoPrezzoPromozione {
    margin-top: 10px;
}

#btnNuovo {
    width: 120px;
    margin-left: 12px
}

#btnCopiaRif {
    width: 170px;
}

.btn-group button {
    margin: 0 7px;
}

#btnLog {
    float: right;
    width: 120px;
    /*margin-left: 980px;*/
}

#btnRiepilogo {
    width: 170px;
    margin-left: 11px;
}

#btnInviaPrezziPromozione {
    float: right;
    width: 140px;
}

#rigaBottoniProdottoPrezzoPromozione {
    padding-top: 30px;
}

#rigaLegendaPrezzoPromozione {
    margin-top: 30px;
}




/*-------------------------------------------------------------------------------------------------------------------*/
/* INFO FARMACIE */

#vediInfoDatabase {
    width: 120px;
}

#btnImpPrezzoPromozione {
    width: 170px;
    margin-left: 60px;
}

#btnImportaPrezzoPromozione {
    width: 170px;
    margin-left: 30px;
}

#rigaTabInfoFarmacia > .infoFarmacia > .dx-widget > .dx-datagrid tr.dx-row.dx-data-row.dx-row-lines.dx-column-lines > td {
    padding: 3px !important;
    margin: 0;
    vertical-align: middle;
}

/*-------------------------------------------------------------------------------------------------------------------*/
/*HOMEPAGE*/






/*-------------------------------------------------------------------------------------------------------------------*/
/*FARMACLICK*/

#btnImportaListinoFarmaclick {
    float: right;
}



/*--------------------------------------------------------------------------------------------------------------------
    DATI ALTRE FARMACIE
*/

.tableDatiAltreFarmacie > td, th {
    padding-right: 148px
}

.tableDatiAltreFarmacieVisualizza {
    padding-right: 50px
}

#btnSalvaDatiAltreFarmacie {
    margin-left: 400px;
    width: 120px;
}
/*--------------------------------------------------------------------------------------------------------------------
    TARIFFAZIONE
*/
#lblInLinea {
    background-color: #B5D5E9;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

#lblScansionano {
    background-color: #B5D5E9;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

#lblConFlussi {
    background-color: #B5D5E9;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

#lblEmetteDistinta {
    background-color: #B5D5E9;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    cursor: help;
}

#btnCerca730{
    margin-top:25px;
    width: 150px;
}

/*-------------------------------------------------------------------------------------------------------------------*/
/*SCROLLBAR IN GRID*/
/* 
.dx-scrollbar-vertical > .dx-scrollbar-hoverable {  
    width: 25px;  
    height: 20px;
}  
.dx-scrollbar-vertical > .dx-scrollable-scroll {  
    width: 25px;
    height: 20px;  
}  */
