.grid-first {
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr;
    grid-gap: 5px;
    font-size: 14px;
    color: #5b6372;
}

    .grid-first div {
        text-align: center;
        line-height: 20px;
        padding: 2px 2px;
    }

.grid-container-4-element {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
    font-size: 14px;
    color: #5b6372;
}

    .grid-container-4-element div {
        text-align: center;
        line-height: 20px;
        padding: 2px 2px;
    }

.grid-container-3-element {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1px;
    font-size: 14px;
    color: #5b6372;
}

    .grid-container-3-element div {
        text-align: center;
        line-height: 20px;
        padding: 2px 2px;
    }


.grid-ibrid {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-gap: 1px;
    font-size: 14px;
    color: #5b6372;
}

    .grid-ibrid div {
        text-align: center;
        line-height: 20px;
        padding: 2px 2px;
    }


.grid-container-2-element {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-size: 14px;
    color: #5b6372;
    grid-gap: 5px;
}

    .grid-container-2-element div {
        text-align: center;
        line-height: 20px;
        padding: 2px;
    }

.grid-container-sec {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    font-size: 14px;
    margin-top: 4px;
    color: #5b6372;
}

    .grid-container-sec div {
        text-align: center;
        line-height: 20px;
        padding: 2px 2px;
    }


.grid-container-one {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-gap: 5px;
    font-size: 14px;
    margin-top: 4px;
    color: #5b6372;
}

    .grid-container-one div {
        text-align: center;
        line-height: 20px;
    }

.grid-container-third {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 1fr 1fr;
    grid-gap: 6px;
    font-size: 14px;
    color: #5b6372;
}

    .grid-container-third div {
        text-align: center;
        line-height: 20px;
        padding: 2px 2px;
    }

.wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    line-height: 20px;
    align-items: start;
    background-color: "white";
}

.wrapper2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    line-height: 20px;
    align-items: start;
}


.firstPie {
    display: flex;
    justify-content: center;
    background-color: "white";
}

.newPieRice {
    display: flex;
    justify-content: center;
    background-color: "white";
}

.secondPie {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    height: 80%;
    margin-top: 20px;
}


.containButtonAna {
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px;
}

.buttonCerca {
    display: flex;
    gap: 8px;
    top: 0;
    top: -10px;
    left: 0;
    font-weight: bold;
    font-size: 16px;
}

.ibutton-lite-two {
    height: 19px;
    display: grid;
    align-items: center;
    align-content: center;
    border: 1px solid #f9f8f8;
    border-radius: 14px;
    background-color: whitesmoke;
}
