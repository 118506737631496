
/* .resize-handle {
    position: absolute;
    top: 0;
    right: 0;
    width: 8px; 
    height: 100%;
    cursor: ew-resize; 
} */

.ibutton{
    height: 19px;
    display: grid;
    align-items: center;
    align-content: center;
    border: 1px solid #f9f8f8;
    border-radius: 14px;
  }

/* Stile per il container dei filtri */
.containerFilter {
    display: flex;
    flex-direction: column;  
    border-radius: 8px;
    top: 50px;
    right: 0;
    bottom: 0;
    width: 420px;
    height: 800px;
    padding: 10px;
    gap: 5px;
    background: white;
    border-radius: 0;
    overflow: hidden; 
}

.containButton {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 16px;
}

.buttonCerca {
    display: flex;
    gap: 8px;  
    top: 0;
    top: -10px;
    left: 0;
    font-weight: bold;
    font-size: 16px;
}

.total-container {
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.total-title {
    font-family: 'Font del titolo del chart', sans-serif;
    
}

.total-value {
    font-family: 'Font del valore del totale', sans-serif;
    font-weight: bold;
    color: #00CEE6;
    
}

.azzeraBtn {
    font-weight: bold;
    font-size: 16px;
}

.filters-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    overflow-y: auto;
}

.main {
    display: inline-flex;
    background: white;
    padding: 10px;
    border-radius: 10px;
    margin-top: 2px;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100% - 60px); /* Sottraiamo l'altezza del titolo e del pulsante "CERCA" */
    overflow: hidden;
}

.mainOut {
    display: none;
    background: white;
    padding: 10px;
    border-radius: 10px;
    margin-top: 2px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    animation: fadeOut ease 1s;
    -webkit-animation: fadeOut ease 1s;
    -moz-animation: fadeOut ease 1s;
    -o-animation: fadeOut ease 1s;
    -ms-animation: fadeOut ease 1s;
}

.btn {
    border-radius: 4px;
    font-size: 14px;
    padding: 9px 15px;
}

    .autoBtn {
        background: white;
        padding: 8px;
        display: inline-block;
        border-radius: 6px;
        border: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: auto;
    }

.troncamento {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 98%;
}

.gridFilterRicerca {
    display: flex;
    gap: 10px;
    grid-template-columns: minmax(100px, max-content) repeat(auto-fill, 300px) 10%;
    flex-wrap: wrap;
}

.dx-list-item-content {
    white-space: break-spaces;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
    background-color: #1ab293;
    color: #fff;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
    background-color: #1ab293;
    color: #fff;
}

.css-1s2u09g-control {
    height: 35px !important;
    min-height: 0 !important;
}

@media (min-width: 268px) and (max-width: 1222px) {
    .gridFilterRicerca div {
        width: auto !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .gridFilterRicerca {
        display: inline-block;
        width: 100%;
    }

        .gridFilterRicerca div {
            width: 100% !important;
        }
}

@media only screen and (max-width: 600px) {
    .gridFilterRicerca {
        display: inline-block;
        width: 100%;
    }

        .gridFilterRicerca div {
            width: 100% !important;
        }
}


@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}