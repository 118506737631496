
/*--------------CSS Codes For Allineamento Prezzi Page-----------------*/

.main-topic {
    font-size: 15px;
    margin-right: 10px;
    margin-top: 5px;
}

.sub-topic {
    font-size: 11px;
}

.chevron-up {
    border: none; 
    background: none; 
    font-size: 20px; 
    margin-left: 10px; 
    margin-top: -10px;
    float: right
}

.divider {
    width: auto;
    margin-top: -2px; 
    margin-bottom: 20px;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Space between buttons */
}

.column-filters {
    flex: 0 0 24%
}

.tabella-btn {
    position: absolute;
    max-width: 150px;
    z-index: 2;
    margin-top: -40px;
    right: 1.5%;
}

.button-wrapper {
    display: flex;
    padding: 0 5px;
    flex: 1; /* Make all columns equal width */
    min-width: 0; /* Allow columns to shrink */
}

/* Top buttons (Cerca & Nuovo Prodotto) */
.btn-cerpro {
    width: 100%; /* Take full width of column */
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

/* Bottom buttons (Annulla & Farmacia Riferimento) */
.btn-anurifer {
    width: 100%; /* Take full width of column */
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.btn-cerpro,
.btn-anurifer {
    height: 38px; /* Set fixed height - adjust as needed */
    width: calc(100% - 20px); /* Reduces width by 20px */
    line-height: 1; /* Proper vertical alignment */
    padding-top: 8px; /* Consistent padding */
    padding-bottom: 8px; /* Consistent padding */
    box-sizing: border-box; /* Include padding in height */
}

/* Responsive behavior */
@media (max-width: 1470px) {
    .btn-cerpro .btn-text,
    .btn-anurifer .btn-text {
        display: none;
    }
    .btn-cerpro,
    .btn-anurifer {
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .button-wrapper {
        flex-direction: column;
        gap: 5px;
    }
    .btn-cerpro,
    .btn-anurifer {
        margin-top: 5px;
    }
}

/*--------------CSS Codes For Allineamento Prezzi Pop Up Dialog Pages-----------------*/

.main-title {
    font-size: 1.6rem;
    font-weight: bold;
    color: #686a6c;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center; /* Centers the text */
}

.sub-title {
    font-size: 0.6rem;
    font-weight: bold;
    color: #686a6c;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center; /* Centers the text */
}

/*CSS Code For Mainly The Button Left To Chiudi*/
.PopFirst-btn {
    width: 120px;
    height: 40px;
   
}

/*CSS Code For All Chiudi Button In Popup Dialog Boxes*/
.PopSecond-btn {
    width: 120px;
    height: 40px;
    font-size: 13px;
    border: thin;
    border-style: ridge;
    border-radius: 4px;
    background-color: white;
    border-color: lightgray;
}